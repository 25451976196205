import emailjs, { init } from "@emailjs/browser";
import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

export default function Email() {
  init("ngmcceu739LmZXvcB");
  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_qjn2ued",
        "template_9upyb9h",
        form.current,
        "ngmcceu739LmZXvcB"
      )
      .then(
        (result) => {
          alert("Mensagem enviada!");
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };


  const [verified, setVerified] = useState(false)

  function onChange(value) {
    setVerified(true);
  }

  return (
    <div className="pt-20 pb-20 bg-white">
      <section className="p-4">
        <form
          className="container w-full max-w-2xl p-8 mx-auto space-y-6 shadow bg-[#0c0c25] ng-untouched ng-pristine ng-valid"
          onSubmit={handleSubmit}
          ref={form}
        >
          <h2 className="w-full text-2xl text-center font-bold leading-tight text-slate-200">
            CONTATO POR E-MAIL
          </h2>
          <div>
            <input
              id="name"
              name="name"
              type="text"
              placeholder="Nome"
              required
              className="block w-full p-2 rounded focus:outline-none focus:ring focus:ring-opacity-25 focus:ring-green-400 dark:bg-white text-slate-900"
            />
          </div>
          <div>
            <input
              id="email"
              name="email"
              type="email"
              placeholder="E-mail"
              required
              className="block w-full p-2 rounded focus:outline-none focus:ring focus:ring-opacity-25 focus:ring-green-400 dark:bg-white text-slate-900"
            />
          </div>
          <div>
            <textarea
              id="message"
              name="message"
              type="text"
              placeholder="Mensagem"
              required
              className="block w-full p-2 pb-20 rounded autoexpand focus:outline-none focus:ring focus:ring-opacity-25 focus:ring-green-400 bg-white text-slate-900"
            ></textarea>
          </div>
          {/* <div class="g-recaptcha" data-sitekey="6Ldfx68gAAAAALOiVmvde5zKMyLhoHZSsgj1l0Wv"></div> */}
          <ReCAPTCHA 
            sitekey="6Ldfx68gAAAAALOiVmvde5zKMyLhoHZSsgj1l0Wv"
            onChange={onChange}
            class="g-recaptcha"
          />
          <div>
            <button
              type="submit"
              className="w-full px-4 py-2 font-bold rounded shadow focus:outline-none focus:ring hover:ring focus:ring-opacity-50 bg-green-500 focus:ring-green-400 hover:ring-green-400 text-white"
              disabled={!verified}
            >
              Enviar
            </button>
          </div>
        </form>
        
      </section>
    </div>
  );
}
