import React from "react";

export default function Work() {
  return (
    <div>
      <section className="p-10 bg-[#0c0c25] text-slate-200">
        <div className="container mx-auto space-y-12">
          <h2 className="text-4xl font-bold text-center">
            COMO FUNCIONA O NOSSO TRABALHO
          </h2>
          <div className="grid justify-center grid-cols-4 mx-auto space-y-8 lg:space-y-0">
            <div className="flex items-center justify-center lg:col-span-1 col-span-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="96"
                height="96"
                fill="currentColor"
                class="bi bi-bank"
                viewBox="0 0 16 16"
              >
                <path d="m8 0 6.61 3h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.38l.5 2a.498.498 0 0 1-.485.62H.5a.498.498 0 0 1-.485-.62l.5-2A.501.501 0 0 1 1 13V6H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 3h.89L8 0ZM3.777 3h8.447L8 1 3.777 3ZM2 6v7h1V6H2Zm2 0v7h2.5V6H4Zm3.5 0v7h1V6h-1Zm2 0v7H12V6H9.5ZM13 6v7h1V6h-1Zm2-1V4H1v1h14Zm-.39 9H1.39l-.25 1h13.72l-.25-1Z" />
              </svg>
            </div>
            <div className="flex flex-col justify-center max-w-3xl mx-12 text-center col-span-full lg:col-span-3 lg:text-left">
              <span className="mt-4 dark:text-slate-200 text-lg">
                <p className="mb-2 pb-6">
                  Ouvimos o seu caso e oferecemos a melhor orientação sobre como
                  agir.
                </p>
                <p className="mb-2 pb-6">
                  Mantemos contato ao longo de todo o processo para que você
                  esteja bem informado sobre o andamento da ação.
                </p>
                <p className="mb-2 pb-6">
                  Se houver margem para uma ação na justiça, agimos com rapidez
                  para garantir a resolução mais rápida e favorável possível.
                </p>
              </span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
