import "./App.css";

import Email from "./components/Email";
import Footer from "./components/Footer";
import Help from "./components/Help";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import Rights from "./components/Rights";
import WhatsApp from "./components/WhatsApp";
import Work from "./components/Work";


function App() {
  return (
    <div>
      <main>
        <Navbar />
        <Hero />
        <Rights />
        <Help />
        <Work />
        <Email />
        <Footer />

        <WhatsApp />
      </main>
    </div>
  );
}

export default App;
