import React from "react";

export default function Navbar() {
  return (
    <div className="sticky top-0">
      <nav className="flex justify-center py-2 mx-auto sticky z-10 bg-white shadow-lg">
        <div className="justify-center p-2">
          <img src="logo.png" alt="Logo" width="200px"/>
        </div>
      </nav>
    </div>
  );
}
