import React, { useState } from "react";

export default function WhatsApp() {
 
  const [isShown, setIsShown] = useState(true);

  const closeBalloon = () => {
    // 👇️ toggle visibility
    setIsShown(current => !current);
  };

  return (
    <div>
      <div className="balao container p-2" style={{display: isShown ? 'block' : 'none'}}>
        <button id="balloon" className="float-right" onClick={closeBalloon}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-x-lg"
            viewBox="0 0 16 16"
          >
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
          </svg>
        </button>
        <p className="-mt-1">Quer conversar com um advogado agora? </p>
      </div>
      <a
        href="https://api.whatsapp.com/send?phone=5575981668550"
        className="float hover:bg-green-600"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fa fa-whatsapp my-float"></i>
      </a>
    </div>
  );
}